body {
  margin: 0;
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  background-color: #f2f2f27a !important;
}

.wh {
  color: #fff !important;
}

h1 {
  font-size: 35px !important;
  line-height: 48px !important;
}

h2 {
  font-size: 30px !important;
  line-height: 32px !important;
}

h3 {
  font-size: 22px !important;
  line-height: 26px !important;
}

h4 {
  font-size: 16px !important;
  line-height: 22px !important;
}

h5 {
  font-size: 15px !important;
  line-height: 26px !important;
}

h6 {
  font-size: 12px !important;
  line-height: 18px !important;
}

.fw3 {
  font-weight: 300 !important;
}

.fw4 {
  font-weight: 400 !important;
}

.fw5 {
  font-weight: 500 !important;
}

.fw6 {
  font-weight: 600 !important;
}

.fw7 {
  font-weight: 700 !important;
}

.fs11 {
  font-size: 11px !important;
}

.fx_sb {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.fx_c {
  display: flex !important;
  justify-content: center !important;
}

.fx_fs {
  display: flex !important;
  justify-content: flex-start !important;
}

.fx_fe {
  display: flex !important;
  justify-content: flex-end !important;
}

.ai_end {
  align-items: end !important;
}

.ai_center {
  align-items: center !important;
}

.al_center {
  text-align: center !important;
}

.al_left {
  text-align: left !important;
}

.al_right {
  text-align: right !important;
}

.al_jst {
  text-align: justify !important;
}

.grid_center {
  display: grid !important;
  align-items: center !important;
  align-content: center !important;
}

.ptr {
  cursor: pointer !important;
}


.one {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 58px !important;
}

.three {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 68px !important;
}

.whitebx {
  padding: 12px !important;
  background-color: #fff !important;
  border-radius: 6px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.wi100 {
  width: 100% !important;
}

.page_wraper {
  margin-top: 70px !important;
}



.navbar {
  background-color: #1930ab !important;
  height: 60px !important;
}

.heading {
  margin-left: 10px !important;
  font-size: 16px !important;
  margin-top: 9px !important;
}

.question {
  color: #1930ab !important;
  border-bottom: 2px solid #efefef !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
  font-weight: 500 !important;

}

.ansbx {
  display: grid !important;
  text-align: center !important;
  justify-content: center !important;
}

.option {
  font-size: 13px !important;
  color: #333 !important;
  font-weight: 500 !important;
  margin-bottom: 0px !important;
}

.risk_meter_img {
  width: 70% !important;
}

.resk_meter_subheading {
  font-size: 11px !important;
  font-weight: 400 !important;
}

.form-check-input[type="radio"] {
  font-size: 12px !important;
}

.form-check-input:checked {
  background-color: #1930ab !important;
  border-color: #1930ab !important;
  box-shadow: none !important;
}

.flip-card {
  padding: 8px !important;
  display: flex !important;
  justify-content: center !important;
}

.flip-card-inner {
  position: relative;
  width: 100px;
  height: 100px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.btn {
  box-shadow: none !important;
}

.back_btn {
  background-color: #1930ab !important;
  padding: 3px 12px !important;
  font-size: 14px !important;
  border-radius: 26px !important;
  font-weight: 500 !important;
  border: solid 2px #1930ab !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.back_btn:hover {
  border: solid 2px #1930ab !important;
  background-color: transparent !important;
  color: #1930ab !important;
}

.proceed_btn {
  background-color: #1930ab !important;
  padding: 4px 12px !important;
  font-size: 11px !important;
  border-radius: 6px !important;
  border: solid 1px #1930ab !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.al_center_mob {
  text-align: center !important;
}

.page-name {
  background: #f2f2f2 !important;
  padding: 0px 20px !important;
  padding-left: 20px;
  padding-left: 0px !important;
  border-bottom-right-radius: 40px !important;
  border-top-right-radius: 40px !important;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset !important;
  box-shadow: inset 0px 4px 5px 1px rgba(130, 130, 130, 0.2) !important;
  margin-bottom: 20px !important;
}

.page-name {
  display: flex !important;
  align-items: center !important;
}

.bullet-point {
  display: grid !important;
  margin-right: 10px !important;
}

.bullet-point .span1 {
  height: 31px !important;
  width: 6px !important;
  background-color: #00b0f0;
  border-radius: 12px !important;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.bullet-point .span2 {
  height: 31px !important;
  width: 6px !important;
  background-color: #1930ab;
  border-radius: 12px !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}


@media (min-width: 767px) {

  .question {
      font-size: 18px !important;
      font-weight: 500 !important;
      text-align: left !important;
      padding: 20px !important;
  }

  .flip-card-inner {
      height: 150px !important;
      width: 150px !important;
  }

  .option {
      font-size: 17px !important;
      margin-top: 10px !important;
  }

  .ansbx {
      margin: 40px !important;
  }

  .ansbx:hover .flip-card-inner {
      transform: rotateY(180deg);
  }

  .back_btn {
      padding: 6px 18px !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      margin-right: 10px !important;
  }

  .form-check-input[type="radio"] {
      font-size: 13px !important;
  }

  .al_center_mob {
      text-align: right !important;
  }

  .risk_meter_img {
      width: 80% !important;
      margin: 30px 0px !important;
  }

  .resk_meter_subheading {
      font-size: 15px !important;
      font-weight: 500 !important;
      line-height: 21px !important;
  }

  .proceed_btn {
      padding: 6px 18px !important;
      font-size: 14px !important;
      font-weight: 500 !important;
  }

  .whitebx {
      min-height: 472px !important;
  }

}


@media (max-width: 576px) {
  .heading {
      font-size: 14px !important;
  }

  .question {
      font-size: 12px !important;
      line-height: 16px !important;
  }

  .option {
      font-size: 12px !important;
      line-height: 14px !important;
  }

  .flip-card-inner {
      height: 90px !important;
      width: 90px !important;
  }
}